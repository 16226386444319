var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{class:( _obj = {}, _obj[_vm.$style.menu] = true, _obj[_vm.$style.white] = _vm.settings.menuColor === 'white', _obj[_vm.$style.gray] = _vm.settings.menuColor === 'gray', _obj[_vm.$style.dark] = _vm.settings.menuColor === 'dark', _obj[_vm.$style.unfixed] = _vm.settings.isMenuUnfixed, _obj[_vm.$style.shadow] = _vm.settings.isMenuShadow, _obj ),attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":_vm.settings.isMobileView ? false : true,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView},on:{"collapse":_vm.onCollapse}},[_c('div',{class:_vm.$style.menuOuter,style:({
      width: _vm.settings.isMenuCollapsed && !_vm.settings.isMobileView ? '80px' : _vm.settings.leftMenuWidth + 'px',
      height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
    })},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2",attrs:{"src":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView ? '/resources/images/logo-icon.svg' : '/resources/images/logo.svg',"alt":"Jipink"}}),_c('div',{class:_vm.$style.descr},[_c('a-tag',[_vm._v(_vm._s(_vm.version))])],1)])]),_c('vue-custom-scrollbar',{style:({
        height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',
      })},[_c('a-menu',{class:_vm.$style.navigation,attrs:{"forceSubMenuRender":"","inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"mode":'inline',"selectedKeys":_vm.selectedKeys,"openKeys":_vm.openKeys,"inlineIndent":15},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(_vm.$auth.granted(item.scopes))?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t(item.title)))])],2):_vm._e(),(!item.children && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e()]:_vm._e()]})],2),_c('div',{class:_vm.$style.banner},[_c('i',{staticClass:"fe fe-headphones"}),_c('h4',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t('msg.banner.title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('msg.banner.subtitle')))]),_c('a',{staticClass:"btn btn-sm btn-success btn-rounded px-3",attrs:{"href":"https://academy.jipink.com/docs/intro/?utm_campaign=adoption&utm_source=desk&utm_medium=support","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('msg.banner.action'))+" ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }