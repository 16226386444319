import Vue from 'vue'
import Moment from 'moment'

Vue.filter('elapsed', value => {
  if (value) {
    return Moment(String(value)).fromNow()
  }
})

Vue.filter('delayed', value => {
  if (value) {
    const now = Moment()
    const duration = Moment.duration(now.diff(value))
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    if (days > 0) {
      return `${days}d`
    } else if (hours > 0) {
      return `${hours}h`
    } else {
      return `${minutes}m`
    }
  }
})

Vue.filter('date', value => {
  if (value) {
    const date = Moment(String(value))
    if (date.isSame(new Date(), 'year')) {
      return date.format('DD MMM')
    } else {
      return date.format('DD MMM YYYY')
    }
  }
})

Vue.filter('calendar', value => {
  if (value) {
    return Moment(String(value)).format('dddd DD MMM YYYY [-] h:mm a')
  }
})

Vue.filter('datetime', value => {
  if (value) {
    const date = Moment(String(value))
    if (date.isSame(new Date(), 'year')) {
      return date.format('DD MMM hh:mm a')
    } else {
      return date.format('DD MMM YYYY hh:mm a')
    }
  }
})

Vue.filter('hour', value => {
  if (value) {
    return Moment(String(value)).format('hh:mm a')
  }
})

Vue.filter('timer', value => {
  if (value) {
    const h = Math.floor(value / 1000 / 60 / 60)
    const m = Math.floor((value / 1000 / 60 / 60 - h) * 60)
    return `${h}h ${m}m`
  }
})

Vue.filter('currency', value => {
  return value.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })
})

Vue.filter('default', (value, defaultValue) => {
  if (!value) {
    return defaultValue
  }
  return value
})

Vue.filter('percent', (value, total = 1) => {
  return parseInt((100 * value) / total)
})

Vue.filter('round', (value) => {
  return Math.round(value)
})

Vue.filter('weight', (value) => {
  if (value && value < 1000) {
    return Math.round(value / 1000 * 10) / 10 + 'Kg'
  } else if (value) {
    return Math.round(value / 1000) + 'Kg'
  }
})

Vue.filter('upper', value => {
  if (value) {
    return value.toUpperCase()
  }
})

Vue.filter('lower', value => {
  if (value) {
    return value.toLowerCase()
  }
})

Vue.filter('proper', value => {
  if (value) {
    return value.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
  }
})

Vue.filter('capital', value => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
})

Vue.filter('left', value => {
  if (value) {
    return value.charAt(0).toUpperCase()
  }
})

Vue.filter('number', value => {
  if (value !== null) {
    return value.toLocaleString('de-DE', { maximumFractionDigits: 2 })
  }
})

Vue.filter('hs', value => {
  if (value !== null) {
    const hs = value / 3600
    return Math.round(hs * 10) / 10
  }
})

Vue.filter('k', value => {
  if (value >= 1000) {
    const k = value / 1000
    const v = Math.round(k * 10) / 10
    return `${v}k`
  }
  return value
})

Vue.filter('mask', (value, mask = '*', n = 4) => {
  if (value) {
    return ('' + value).slice(0, -n).replace(/./g, mask) + ('' + value).slice(-n)
  }
})
