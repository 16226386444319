import Vue from 'vue'

const search = (pagination) => {
  return Vue.prototype.$http.get('zones', { params: pagination })
}

const searchByZip = (zip) => {
  return Vue.prototype.$http.get('zones', { params: { zip } })
}

export default {
  search,
  searchByZip,
}
