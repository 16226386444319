import acl from '@/auth/acl'

export const getMenuData = [
  {
    title: 'metrics',
    key: 'metrics',
    icon: 'fe fe-pie-chart',
    url: '/metrics',
    scopes: acl.metrics.general,
  },
  {
    title: 'shipments',
    key: 'shipments',
    icon: 'fe fe-package',
    url: '/shipments',
    scopes: acl.shipments.search,
  },
  {
    title: 'couriers',
    key: 'couriers',
    icon: 'fe fe-truck',
    url: '/couriers',
    scopes: acl.couriers.search,
  },
  {
    title: 'crossdock',
    key: 'crossdock',
    icon: 'fe fe-shuffle',
    url: '/crossdock',
    scopes: acl.crossdock.search,
  },
  {
    title: 'accounts',
    key: 'accounts',
    icon: 'fe fe-briefcase',
    url: '/accounts',
    scopes: acl.accounts.search,
  },
  {
    title: 'payments',
    key: 'payments',
    icon: 'fe fe-credit-card',
    url: '/payments',
    scopes: acl.payments.search,
  },
  {
    title: 'simulator',
    key: 'simulator',
    icon: 'fe fe-dollar-sign',
    url: '/simulator',
    scopes: acl.simulator.search,
  },
  {
    title: 'zones',
    key: 'zones',
    icon: 'fe fe-map',
    url: '/zones',
    scopes: acl.zones.search,
  },
  {
    title: 'tasks',
    key: 'tasks',
    icon: 'fe fe-check-circle',
    url: '/tasks',
    scopes: acl.tasks.search,
  },
  {
    title: 'shops',
    key: 'shops',
    icon: 'fe fe-shopping-bag',
    url: '/shops',
    scopes: acl.shops.search,
  },
  {
    title: 'exports',
    key: 'exports',
    icon: 'fe fe-file-text',
    url: '/exports',
    scopes: acl.exports.create,
  },
  {
    title: 'users',
    key: 'users',
    icon: 'fe fe-users',
    url: '/users',
    scopes: acl.users.search,
  },
  {
    title: 'tokens',
    key: 'tokens',
    icon: 'fe fe-lock',
    url: '/tokens',
    scopes: acl.tokens.search,
  },
  {
    title: 'settings',
    key: 'settings',
    icon: 'fe fe-settings',
    url: '/settings',
    scopes: acl.settings.detail,
  },
]
