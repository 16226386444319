import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    connected: [],
    available: [],
  },
  mutations: {
    SET_CONNECTED(state, payload) {
      state.connected = payload
    },
    SET_AVAILABLE(state, payload) {
      state.available = payload
    },
  },
  getters: {
    CONNECTED: state => state.connected,
    AVAILABLE: state => state.available,
  },
}
