import Vue from 'vue'

const search = (query, type) => {
  const params = { q: query, type }
  return Vue.prototype.$http.get('suggestions', { params })
}

export default {
  search,
}
