<template>
  <div :class="$style.topbar">
    <!-- Theme Switcher -->
    <div class="mr-4">
      <j-theme-switcher />
    </div>
    <!-- Search -->
    <div class="mr-auto">
      <!-- <cui-search /> -->
    </div>
    <!-- Language Switcher -->
    <div class="mr-4 d-none d-sm-block">
      <j-language-switcher />
    </div>
    <!-- Notifications -->
    <!-- <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div> -->
    <div>
      <j-user-menu />
    </div>
  </div>
</template>

<script>
// import CuiSearch from './Search'
// import CuiActions from './Actions'
import JLanguageSwitcher from './LanguageSwitcher'
import JThemeSwitcher from './ThemeSwitcher'
import JUserMenu from './UserMenu'

export default {
  components: {
    // CuiSearch,
    // CuiActions,
    JLanguageSwitcher,
    JThemeSwitcher,
    JUserMenu,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
