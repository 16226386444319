import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    zones: [],
    routes: [],
    metrics: {},
    tasks: [],
  },
  mutations: {
    SET_ZONES(state, payload) {
      state.zones = payload
    },
    SET_ROUTES(state, payload) {
      state.routes = payload
    },
    SET_METRICS(state, payload) {
      state.metrics = payload
    },
    SET_TASKS(state, payload) {
      state.tasks = payload
    },
  },
  getters: {
    ZONES: state => state.zones,
    ROUTES: state => state.routes,
    METRICS: state => state.metrics,
    TASKS: state => state.tasks,
  },
}
