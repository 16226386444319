import bff from './lms.bff'
import courier from './lms.courier'
import shipment from './lms.shipment'
import account from './lms.account'
import platform from './lms.platform'
import label from './lms.label'
import zone from './lms.zone'
import quote from './lms.quote'
import exports from './lms.export'
import user from './lms.user'
import metrics from './lms.metrics'
import payment from './lms.payment'
import suggestion from './lms.suggestion'
import task from './lms.task'
import troubleshoot from './lms.troubleshoot'

export default {
  bff,
  courier,
  shipment,
  account,
  platform,
  label,
  zone,
  quote,
  exports,
  user,
  metrics,
  payment,
  suggestion,
  task,
  troubleshoot,
}
