import Vue from 'vue'

const getRoutes = (pagination) => {
  return Vue.prototype.$http.get('dk/crossdock/routes', { pagination })
}

const getMetrics = (account) => {
  return Vue.prototype.$http.get('dk/crossdock/metrics', { params: { account } })
}

const getPickingOrderList = (printer) => {
  return Vue.prototype.$http.get('dk/picking-order/list', { params: { printer } })
}

const getPickingOrderLabels = (printer) => {
  return Vue.prototype.$http.get('dk/picking-order/labels', { params: { printer } })
}

const parseCSV = (type, file) => {
  const body = new FormData()
  body.append('file', file)
  return Vue.prototype.$http.post('dk/shipments', body, {
    params: { type },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default {
  getMetrics,
  getRoutes,
  getPickingOrderList,
  getPickingOrderLabels,
  parseCSV,
}
