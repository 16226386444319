import localeAntd from 'ant-design-vue/lib/locale-provider/es_ES'

const messages = {
  account: 'Cuenta',
  accountId: 'Account ID',
  accounts: 'Cuentas',
  actions: 'Acciones',
  active: 'Activo',
  address: 'Dirección',
  admin: 'Administrador',
  and: 'y',
  anonymous: 'Anónimo',
  apartment: 'Piso / Departamento',
  apiKey: 'API Key',
  apply: 'Aplicar',
  author: 'Autor',
  available: 'Disponible',
  back: 'Volver',
  before: 'Antes',
  cancel: 'Cancelar',
  city: 'Ciudad',
  clear: 'Limpiar',
  code: 'Código',
  company: 'Empresa',
  confirm: 'Confirmar',
  connect: 'Conectar',
  contact: 'Contacto',
  continue: 'Continuar',
  courier: 'Transportista',
  couriers: 'Transportistas',
  courierType: 'Tipo de Transportista',
  create: 'Crear',
  created: 'Creado',
  crossdock: 'Crossdock',
  currency: 'Moneda',
  cutoff: 'Horario de Corte',
  dashboard: 'Dashboard',
  date: 'Fecha',
  dateMin: 'Fecha Desde',
  dateMax: 'Fecha Hasta',
  delayed: 'Demorado',
  delete: 'Eliminar',
  delivery: 'Envío',
  destination: 'Destino',
  detail: 'Detalle',
  dispatched: 'Despachado',
  edit: 'Editar',
  edited: 'Editado',
  email: 'Email',
  events: 'Eventos',
  exchange: 'Cambio',
  expiration: 'Expiración',
  expired: 'Expirado',
  expires: 'Expira',
  export: 'Exportar',
  exported: 'Exportado',
  exports: 'Reportes',
  extraMin: 'Días Extra Mínimo',
  extraMax: 'Días Extra Máximo',
  filter: 'Filtrar',
  filtered: 'Filtrado',
  finish: 'Finalizar',
  form: 'Formulario',
  format: 'Formato',
  general: 'General',
  hello: 'Hola',
  history: 'Historial',
  identification: 'Documento',
  import: 'Importar',
  issue: 'Incidencia',
  labels: 'Etiquetas',
  leadTime: 'Lead Time',
  loading: 'Cargando',
  locality: 'Barrio / Localidad',
  logout: 'Salir',
  main: 'Principal',
  method: 'Método',
  metrics: 'Métricas',
  mock: 'Imitar',
  name: 'Nombre',
  new: 'Nuevo',
  next: 'Próximo',
  noname: 'Sin Nombre',
  now: 'Ahora',
  no: 'No',
  notes: 'Notas',
  operation: 'Operación',
  order: 'Orden',
  origin: 'Origen',
  outsource: 'Código de Correo',
  of: 'de',
  package: 'Paquete',
  packages: 'Paquetes',
  payment: 'Pago',
  payments: 'Pagos',
  phone: 'Teléfono',
  platform: 'Fuente',
  platforms: 'Integraciones',
  preview: 'Vista Previa',
  print: 'Imprimir',
  printer: 'Impresora',
  property: 'Campo',
  rate: 'Tarifa',
  rates: 'Tarifas',
  receiver: 'Receptor',
  ref: 'Código de Referencia',
  refresh: 'Refrescar',
  region: 'Grupo',
  results: 'Resultados',
  retry: 'Reintentar',
  return: 'Devolución',
  role: 'Rol',
  roles: 'Roles',
  route: 'Ruta',
  routes: 'Rutas',
  scope: 'Permisos',
  search: 'Buscar',
  select: 'Seleccionar',
  sender: 'Remitente',
  settings: 'Configuración',
  shipment: 'Envío',
  shipments: 'Envíos',
  shop: 'Tienda',
  shops: 'Tiendas',
  simulator: 'Cotizador',
  size: 'Tamaño',
  sort: 'Ordenar',
  state: 'Provincia',
  status: 'Estado',
  store: 'Almacén',
  stores: 'Almacenes',
  street: 'Calle y Número',
  substatus: 'Sub-Estado',
  support: 'Ayuda',
  tasks: 'Tareas',
  template: 'Link',
  templates: 'Autogestión',
  to: 'a',
  tracking: 'Tracking',
  type: 'Tipo',
  token: 'Credencial',
  tokens: 'Credenciales',
  troubleshooter: 'Diagnóstico',
  unknown: 'Desconocido',
  unscheduled: 'A programar',
  update: 'Editar',
  updated: 'Actualizado',
  user: 'Usuario',
  users: 'Usuarios',
  value: 'Valor',
  vehicle: 'Vehículo',
  volume: 'Volumen',
  waybills: 'Despacho',
  yes: 'Sí',
  zip: 'Código Postal',
  zone: 'Zona',
  zones: 'Zonas',
  enum: {
    activation: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
      PENDING: 'Pendiente',
    },
    collect: {
      ON_DEMAND: 'Colecta Flexible',
      STANDARD: 'Colecta Estándar',
    },
    courierType: {
      COMPANY: 'Compañía',
      SINGLE: 'Individuo',
    },
    csv: {
      WIN: 'Jipink (Windows)',
      MAC: 'Jipink (Mac)',
      SHF: 'Shopify',
      MAG: 'Magento',
    },
    currency: {
      ARS: 'ARS (Peso Argentino)',
      USD: 'USD (Dólar Estadounidense)',
    },
    dateKey: {
      LAST_DAY: 'Últimas 24 horas',
      LAST_WEEK: 'Última semana',
      LAST_MONTH: 'Último mes',
      LAST_SIX_MONTHS: 'Últimos 6 meses',
      LAST_YEAR: 'Último año',
    },
    dateType: {
      CREATED: 'Fecha de creación',
      UPDATED: 'Fecha de modificación',
      PROMOTED: 'Fecha de último estado',
      DISPATCHED: 'Fecha de último despacho',
    },
    direction: {
      BACKWARD: 'Rescate',
      FORWARD: 'Entrega',
    },
    flex: {
      FLEX_NOT_PICKED: 'No escaneado',
      FLEX_NOT_MARKED: 'No reportado',
      FLEX_NOT_DELIVERED: 'No entregado',
    },
    issue: {
      ABSENT_RECEIVER: 'Dest. ausente',
      BROKEN: 'Paquete dañado',
      BUSINESS_HOURS: 'Dom. laboral',
      CANCELED: 'Cancelado',
      DUPLICATED: 'Duplicado',
      INCORRECT_ADDRESS: 'Dom. incorrecto',
      LOST: 'Extraviado',
      NOT_VISITED: 'No visitado',
      ON_VACATIONS: 'Vacaciones',
      OVERSIZE: 'Tamaño excedido',
      REJECTED: 'Rechazado',
      RESCHEDULED: 'Reprogramado',
      STOLEN: 'Robado',
      UNREACHABLE_AREA: 'Inaccesible',
      UNREACHABLE_RECEIVER: 'Dest. no responde',
      VEHICLE_ISSUE: 'Siniestro',
      WRONG_PRODUCT: 'Prod. incorrecto',
      EXHAUSTED: 'Intentos agotados',
    },
    method: {
      EXPRESS: 'Rápido',
      STANDARD: 'Estándar',
      P2P: 'Punto a Punto',
    },
    payment: {
      APPROVED: 'Aprobado',
      PENDING: 'Pendiente',
      PROCESSING: 'En proceso',
      REJECTED: 'Rechazado',
    },
    plan: {
      BRONZE: 'Bronce',
      SILVER: 'Plata',
      GOLD: 'Oro',
      PLATINUM: 'Platino',
      BLACK: 'Black',
    },
    printer: {
      PDF_A4: 'Normal - PDF A4 (210 x 297 mm)',
      PDF_A7: 'Térmica - PDF A7 (74 x 105 mm)',
      ZPL: 'Térmica - ZPL (100 x 150 mm)',
    },
    size: {
      S: 'S - Máximo 5 kg',
      M: 'M - Máximo 10 kg',
      L: 'L - Máximo 20 kg',
      XL: 'XL - Máximo 40 kg',
    },
    sort: {
      NEWEST: 'Ordenar por más reciente',
      OLDEST: 'Ordenar por más antiguo',
      STUCKEST: 'Ordenar por más atascado',
    },
    source: {
      JPK: 'Jipink',
      OMN: 'Omnicanal',
      MLI: 'MercadoLibre',
      P2P: 'P2P',
      TNB: 'Tiendanube',
      VTX: 'Vtex',
      WOO: 'WooCommerce',
      SHF: 'Shopify',
    },
    status: {
      ABORTED: 'Anulado',
      ADMITTED: 'En correo',
      BLOCKED: 'Bloqueado',
      CANCELED: 'Cancelado',
      COLLECTED: 'Colectado',
      CONFIRMED: 'En camino',
      CONSOLIDATED: 'Consolidado',
      DELIVERED: 'Entregado',
      DISPATCHED: 'Despachado',
      EXPIRED: 'Expirado',
      LOST: 'Extraviado',
      NOT_VISITED: 'No visitado',
      OUT_FOR_DELIVERY: 'Entrega en tránsito',
      OUT_FOR_RETURN: 'Retorno en tránsito',
      OUT_FOR_RECOVERY: 'Rescate en tránsito',
      OUT_FOR_COLLECTION: 'Colecta en tránsito',
      PICK_UP: 'En sucursal',
      RECEIVED: 'Recibido',
      RECOVERED: 'Rescatado',
      RETURNED: 'Retornado',
      STOLEN: 'Robado',
      TO_APPROVE: 'A confirmar',
      TO_COLLECT: 'A colectar',
      TO_CONSOLIDATE: 'A consolidar',
      TO_DELIVER: 'A entregar',
      TO_RECEIVE: 'A recibir',
      TO_RECOVER: 'A rescatar',
      TO_RETURN: 'A retornar',
      VISITED: 'Visitado',
    },
    type: {
      DELIVERY: 'Envío',
      EXCHANGE: 'Cambio',
      RETURN: 'Devolución',
    },
    vehicle: {
      BIKE: 'Bici',
      CAR: 'Auto',
      MOTORBIKE: 'Moto',
      VAN: 'Utilitario',
    },
  },
  msg: {
    abm: {
      action: {
        create: 'Crear',
        update: 'Modificar',
      },
      delete: {
        alert: '¿Estás seguro que querés eliminar? ',
        subtitle: 'Una vez que lo elimines, no habrá vuelta atrás.',
        title: 'Eliminar',
      },
    },
    account: {
      action: {
        activate: 'Activar Cuenta',
        deactivate: 'Desactivar Cuenta',
        edit: 'Editar Datos',
        mock: 'Operar Cuenta',
        rate: 'Editar Tarifas',
        store: 'Editar Almacenes',
        shop: 'Ver Tiendas',
        template: 'Editar Links',
        troubleshoot: 'Diagnóstico',
        token: 'Editar Tokens',
        user: 'Activar Usuario',
      },
      activated: 'La cuenta fue activada',
      deactivated: 'La cuenta fue desactivada',
      granted: 'El usuario fue activado',
      name: 'Cuentas',
      subtitle: 'Administrá las cuentas que operan con la plataforma.',
      title: 'Todas las cuentas',
    },
    action: {
      APPROVE: 'Se aprobó la operación',
      CANCEL: 'Se canceló la operación',
      COLLECT: 'Se informó colecta satisfactoria',
      CONFIRM: 'Se confirmó la pronta visita',
      CONSOLIDATE: 'Se consolidó el paquete',
      DISPATCH: 'Se despachó al transportista',
      DROP: 'Se desasginó al transportista',
      HIT: 'Se informó visita satisfactoria',
      MISS: 'Se reportó una incidencia',
      RECALL: 'Se despachó al transportista',
      RECEIVE: 'Se recibió en el depósito',
      RETURN: 'Se informó retorno satisfactorio ',
      BLOCK: 'Se bloqueó la operación',
      UNBLOCK: 'Se desbloqueó la operación',
      ADMIT: 'Se recibió en el correo',
      PICK_UP: 'Se recibió en una sucursal',
      EXPIRE: 'Se expiró el plazo',
    },
    banner: {
      title: '¿Necesitás ayuda?',
      subtitle: 'Revisá nuestros tutoriales',
      action: 'Ver Tutoriales',
    },
    log: {
      title: 'Historial de Cambios',
      empty: 'No hubo ningún cambio aún',
    },
    copied: 'Copiado a tu portapapeles',
    courier: {
      action: {
        activate: 'Activar Transportista',
        deactivate: 'Desactivar Transportista',
        labels: 'Despachar Etiquetas',
        waybill: 'Imprimir Hoja de Ruta',
        edit: 'Editar Datos',
      },
      activated: 'El transportista fue activado',
      deactivated: 'El transportista fue desactivado',
      name: 'Transportistas',
      subtitle: 'Amdministrá los jippers y mensajerías que distribuyen la paquetería.',
      title: 'Todos los transportistas',
    },
    flag: {
      ERROR: 'Se descartó este evento por tratarse de un error',
      FRAUD: 'Se reportó un posible fraude en este evento',
    },
    error: {
      internal: 'Ocurrió un error interno :(',
    },
    exports: {
      subtitle: 'Generá los reportes que necesites.',
      title: 'Tus reportes',
      message: 'Te enviaremos el reporte a',
      submit: 'Generar Reporte',
    },
    issue: {
      ABSENT_RECEIVER: 'No se encontró nadie en el domicilio',
      BROKEN: 'El paquete está dañado y no puede ser entregado',
      BUSINESS_HOURS: 'El domicilio solo está abierto en horario laboral',
      CANCELED: 'La operación fue cancelada ante de ser completada',
      DUPLICATED: 'La operación ya fue realizada y está duplicada',
      INCORRECT_ADDRESS: 'La dirección es incorrecta o no existe',
      LOST: 'El transportista reportó el extravío de los paquetes',
      NOT_VISITED: 'El transportista no pudo visitar el domicilio',
      ON_VACATIONS: 'El domicilio se encuentra cerrado por vacaciones',
      OVERSIZE: 'El paquete es demasiado grande o pesado para ser transportado',
      REJECTED: 'Se rechazó la operación en el domicilio',
      RESCHEDULED: 'Se reprogramó la operación para una nueva fecha',
      STOLEN: 'El transportista reportó el robo de los paquetes',
      UNREACHABLE_AREA: 'El domicilio está en una zona de difícil acceso',
      UNREACHABLE_RECEIVER: 'El teléfono es incorrecto o nadie atiende',
      VEHICLE_ISSUE: 'El transportista tuvo un problema con su vehículo',
      WRONG_PRODUCT: 'El producto es incorrecto',
      EXHAUSTED: 'Se agotaron los intentos de entrega disponibles.',
    },
    item: {
      subtitle: 'Estos son los items asociados al envío.',
      title: 'Todos los items',
    },
    metrics: {
      banner: {
        top: 'Jipink Academy',
        title: 'Llevá tus envíos al próximo nivel',
        subtitle: 'Encontrá tutoriales de cómo operar con nuestra plataforma, guías para integrar tu tienda, y mucho más en nuestro nuevo sitio de documentación.',
        action: 'Aprender Más',
      },
      counters: {
        active: 'Activos',
        blocked: 'Bloqueados',
        delayed: 'Demorados',
        pending: 'Pendientes',
      },
      dv: {
        title: 'Total de hoy',
        tooltip: 'Respecto a la semana anterior',
      },
      lt: {
        title: 'Lead Time',
      },
      mh: {
        title: 'Volumen Mensual',
        total: 'Volumen',
        subtitle: 'respecto al mes anterior',
        empty: 'Aún estamos recopilando datos :)',
      },
      mv: {
        title: 'Total del mes',
        tooltip: 'Respecto al mes anterior',
      },
      su: {
        title: 'Eficacia',
      },
    },
    mock: {
      description: 'Estás operando como {name}. Todo lo que hagas será procesado como si lo estuviera haciendo esta cuenta.',
    },
    offline: {
      description: 'No pudimos conectarnos a nuestros servidores, esto podría deberse a un problema de conexión de tu red.',
      title: 'Sin Conexión',
    },
    usermenu: {
      academy: 'Centro de ayuda',
      password: 'Cambiar contraseña',
      feedback: 'Ayudanos a mejorar',
      troubleshoot: 'Diagnóstico',
    },
    payment: {
      action: {
        receipt: 'Ver Comprobante',
      },
      approved: 'Pagado',
      name: 'Pagos',
      pending: 'Pendiente',
      subtitle: 'Administrá los pagos realizados a través de los medios habilitados.',
      title: 'Todos los pagos',
    },
    platform: {
      action: {
        delete: 'Desconectar',
        health: 'Probar Conexión',
        settings: 'Configurar',
      },
      confirm: {
        delete: 'Dejaremos de recibir los envíos de esta tienda.',
      },
      name: 'Tiendas',
      new: {
        subtitle: 'Conectá tu tienda en pocos pasos utilizando nuestras integraciones disponibles.',
        title: 'Conectar Tienda',
      },
      subtitle: 'Estas son las tiendas que conectaste a nuestra plataforma.',
      title: 'Tus tiendas',
    },
    promote: {
      title: 'Informar Novedad',
      subtitle: 'Informá novedades de envíos que el transportista no haya reportado correctamente. ',
      submit: 'Informar',
      actions: {
        HIT: 'Entregado / Rescatado',
        MISS: 'Hubo un problema',
      },
    },
    rate: {
      action: {
        edit: 'Editar Tarifa',
      },
      name: 'Tarifas',
      subtitle: 'Amdministrá las tarifas y servicios de una cuenta.',
      title: 'Tus tarifas',
    },
    route: {
      labels: 'Imprimir Rescates',
      subtitle: 'Visualizá el volumen de envíos por cada ruta.',
      title: 'Todas las rutas',
      waybills: 'Imprimir Hoja de Ruta',
    },
    settings: {
      subtitle: 'Administrá la configuración de tu cuenta.',
      title: 'Tu configuración',
    },
    shipment: {
      packages: '{count} paquete(s)',
      assignedTo: 'Asignado a',
      createdBy: 'Registrado por',
      updatedBy: 'Modificado por',
      action: {
        block: 'Bloquear Envío',
        cancel: 'Cancelar Envío',
        clone: 'Clonar Envío',
        revert: 'Revertir Estado',
        report: 'Reportar Fraude',
        promote: 'Informar Novedad',
        edit: 'Editar Envío',
        exchange: 'Iniciar Cambio',
        outsource: 'Redespachar',
        printOne: 'Imprimir Etiqueta',
        printFlex: 'Imprimir Flex',
        printOutsource: 'Imprimir Redespacho',
        recover: 'Iniciar Devolución',
        unlock: 'Reintentar',
        view: 'Ver Detalle',
        viewCourier: 'Ver Transportista',
        viewItems: 'Ver Items',
      },
      blocked: {
        subtitle: 'Detectamos un problema con esta operación, y no podemos continuar hasta que tomes una acción.',
        title: '¿Por qué está bloqueado?',
        unlock: {
          subtitle: 'Asegurate de que esté solucionado el problema reportado antes de continuar con esta operación, para evitar incurrir en costos extras.',
          title: '¿Querés desbloquear la operación?',
        },
      },
      confirm: {
        block: 'Se bloqueará la operación, y se solicitará intervención al creador de la misma para resolver el problema.',
        clone: 'Se creará un envío nuevo en nuestra plataforma con los mismos datos del envío de referencia.',
        outsource: 'Se creará un nuevo envío en la plataforma de nuestro partner logístico, y tendrás que imprimir la etiqueta de redespacho.',
        revert: 'Se revertirá el último estado, y se volverá al estado anterior al mismo.',
        report: 'Se marcará el envío como posible fraude por parte del transportista asignado.',
        cancel: 'Esta operación cancelará el envío permanentemente. ¿Estás seguro de continuar?',
        exchange: 'Se creará un nuevo envío para iniciar el cambio. Al momento de entregar el nuevo envío, se retirarán los paquetes correspondientes y se retornarán a tu domicilio.',
        recover: 'Retiraremos los paquetes desde el domicilio del destinatario, y los enviaremos de regreso a tu domicilio.',
      },
      create: {
        action: 'Nuevo Envío',
        single: {
          title: 'Carga Simple',
          subtitle: 'Completá los datos, o importalo desde tu tienda.',
          section1: 'Tipo de operación',
          section2: 'Datos del envío',
          delivery: 'Colectamos el paquete en el almacén, y lo enviamos a la dirección.',
          return: 'Rescatamos el paquete desde la dirección y lo devolvemos al almacén.',
          exchange: 'Enviamos el paquete a la dirección, y al mismo tiempo retiramos otro.',
        },
        multi: {
          title: 'Carga Masiva',
          subtitle: 'Cargá múltiples envíos desde un archivo CSV.',
          section1: 'Carga de Archivo',
          upload: {
            title: 'Hacé click o arrastrá el archivo acá',
            subtitle1: '¿Necesitás un archivo de ejemplo?',
            subtitle2: 'Utilizá el CSV exportado desde tu tienda',
            action1: 'Descargar para Windows',
            action2: 'Descargar para Mac',
          },
        },
      },
      export: {
        title: 'Exportar Envíos',
        subtitle1: 'Estás exportando un total de:',
        subtitle2: 'Una vez generado el archivo, te lo enviaremos a:',
        warning: 'Máximo de {limit} envíos superado',
      },
      exported: {
        action1: 'Volver a Envíos',
        subtitle: 'Cuando el archivo esté listo, te lo enviaremos por email para que puedas descargarlo.',
        title: 'Estamos exportando tus envíos',
      },
      filters: {
        active: 'Activos',
        blocked: 'Bloqueados',
        delayed: 'Demorados',
        notMarked: 'No marcados',
        pending: 'Pendientes',
      },
      flex: {
        action: 'Ver Etiqueta',
        marked: 'Reportado con la APP de Flex',
        picked: 'Escaneado con la APP de Flex',
        delivered: 'Entregado con la APP de Flex',
        title: 'Mercado Envíos Flex',
      },
      imported: 'El envío fue importado',
      label: {
        pending: 'Sin etiquetar',
        printed: 'Etiquetado ✓',
      },
      mode: 'Tipo de Carga',
      multi: {
        request: 'Solicitar Envíos',
      },
      print: {
        title: 'Imprimir Etiquetas',
        subtitle1: 'Estás imprimiendo un total de:',
        subtitle2: 'Utilizaremos el siguiente formato:',
        subtitle3: 'Basado en los filtros que aplicaste en tus envíos.',
        warning: 'Máximo de {limit} etiquetas superado',
      },
      related: {
        detail: 'Envíos que están relacionados con el actual.',
        title: 'Relacionados',
      },
      search: '¿Qué envío buscás?',
      selected: {
        title: 'seleccionado(s)',
        print: 'Imprimir Etiquetas',
        printFlex: 'Imprimir Etiquetas Flex',
        clear: 'Deseleccionar',
      },
      single: {
        request: 'Solicitar Envío',
      },
      success: {
        action1: 'Ver Envío',
        action2: 'Volver a Envíos',
        cancel: '¡Envío cancelado!',
        clone: '¡Envío clonado!',
        create: '¡Envío creado!',
        exchange: '¡Cambio iniciado!',
        recover: '¡Devolución iniciada!',
        revert: '¡Estado revertido!',
      },
      update: 'Guardar Cambios',
    },
    shop: {
      action: {
        connect: 'Conectar',
        tutorial: 'Ir al Tutorial',
        disconnect: 'Desconectar',
        reconnect: 'Reconectar',
        configure: 'Configurar',
        update: 'Renombrar',
      },
      callback: {
        failure: {
          action1: 'Reintentar',
          action2: 'Ir a Tiendas',
          subtitle: 'No se pudo integrar tu tienda a nuestra plataforma.',
          title: 'Falló la integración',
        },
        processing: {
          subtitle: 'Aguardá un momento mientras completamos la integración.',
          title: 'Procesando...',
        },
        success: {
          action: 'Ir a Tiendas',
          subtitle: 'Tu tienda ya está integrada a nuestra plataforma.',
          title: '¡Tienda conectada!',
        },
      },
      flex: {
        title: 'MercadoLibre',
        subtitle: 'Configurá todos los aspectos claves de tus envíos Flex.',
        week: 'Lunes a Viernes',
        saturday: 'Sábados',
        sunday: 'Domingos',
        enable: 'Activar Mercado Envíos Flex',
        cutoff: 'Horario de Corte',
        capacity: 'Límite de Envíos',
        zones: 'Zonas de Cobertura',
        min: 'Horario Mínimo de Entrega',
        max: 'Horario Máximo de Entrega',
        submit: 'Guardar Cambios',
      },
      update: {
        title: 'Modificar Tienda',
        subtitle: 'Modificá los atributos de tu tienda.',
        name: 'Nombre',
        sender: 'Usar este nombre como remitente de los envíos',
        submit: 'Guardar Cambios',
      },
    },
    simulator: {
      calendar: 'Entre',
      methods: 'Métodos Disponibles',
      placeholder: 'Ingresá el código postal a cotizar',
      submit: 'Cotizar',
      subtitle: 'Cotizá un envío por código postal',
      title: 'Cotizador',
    },
    status: {
      ABORTED: 'Se anuló la operación y la misma será interrumpida',
      BLOCKED: 'La operación no puede continuar y se requiere intervención del cliente',
      CANCELED: 'Se canceló la operación',
      COLLECTED: 'Se colectó el paquete en el origen',
      CONFIRMED: 'El domicilio será visitado pronto por el transportista',
      CONSOLIDATED: 'Se consolidó el paquete correctamente dentro de un paquete padre',
      DELIVERED: 'Se entregaró el paquete satisfactoriamente al destinatario',
      DISPATCHED: 'Se asignó un transportista, y el domicilio ya forma parte de su ruta',
      EXPIRED: 'El paquete no fue retirado y retornará nuevamente al origen',
      ADMITTED: 'El paquete fue recibido correctamente en el centro de distribución del correo',
      FETCHED: 'Se retiró el paquete desde el origen',
      LOST: 'Se extravió el paquete y lo debemos colectar nuevamente',
      NOT_VISITED: 'El domicilio aún no fue visitado',
      PICK_UP: 'El paquete está en la sucursal listo para ser retirado',
      RECOVERED: 'Se rescató el paquete desde el destino',
      RETURNED: 'Se devolvió el paquete satisfactoriamente a origen',
      STOLEN: 'El paquete fue robado y debemos colectarlo nuevamente',
      TO_APPROVE: 'La operación aún no fue confirmada y se encuentra en espera',
      TO_COLLECT: 'Ingresó la solicitud para realizar el envío',
      TO_CONSOLIDATE: 'El paquete está en el centro de distribución esperando consolidación',
      TO_DELIVER: 'Se recibió el paquete en nuestro centro de distribución',
      TO_RECOVER: 'Ingresó la solicitud para devolver el envío',
      TO_RETURN: 'Se despachará el paquete de regreso hacia origen',
      TO_RECEIVE: 'A la espera de recibir el paquete en el correo',
      RECEIVED: 'Se recibió el paquete en el correo',
      VISITED: 'Se visitó el domicilio correctamente, pero no se pudo efectivizar la entrega',
    },
    store: {
      action: {
        edit: 'Editar Almacén',
      },
      main: {
        false: 'Almacén Secundario',
        help: 'Un almacén principal es donde se colectan la mayoría de tus envíos.',
        true: 'Almacén Principal',
      },
      name: 'Almacenes',
      subtitle: 'Agregá los depósitos o sucursales que querés utilizar como puntos de colecta.',
      title: 'Tus almacenes',
    },
    task: {
      subtitle: 'Estas son todas las tareas programadas para ejecución.',
      title: 'Todas las tareas',
      executed: 'Última ejecución',
      scheduled: 'Próxima ejecución',
      unexecuted: 'No hubo ninguna ejecución aún',
      unscheduled: 'No hay próxima ejecución programada',
    },
    template: {
      action: {
        edit: 'Editar Link',
      },
      expired: {
        false: 'Expira',
        true: 'Expirado',
      },
      everlasting: 'Sin expiración',
      help: 'Si está habilitado, el usuario deberá abonar el envío con MercadoPago.',
      name: 'Links',
      payment: {
        false: 'No solicitar pago',
        true: 'Solicitar pago',
      },
      subtitle: 'Creá links para que tus clientes o sucursales puedan cargar envíos asociados a tu cuenta.',
      title: 'Tus links de autogestión',
    },
    theme: {
      dark: 'Modo Oscuro',
      default: 'Modo Claro',
    },
    token: {
      name: 'Credenciales',
      subtitle: 'Creá credenciales para que aplicaciones de terceros tengan acceso a tu cuenta.',
      title: 'Tus credenciales',
    },
    troubleshoot: {
      title: 'Diagnóstico',
      subtitle: 'Intentaremos detectar problemas técnicos en tu configuración y/o integraciones.',
      run: 'Iniciar Diagnóstico',
      retry: 'Reiniciar',
      fix: 'Solucionar',
    },
    undefined: 'No hay {name}',
    user: {
      name: 'Usuarios',
      subtitle: 'Gestioná los accesos que tienen los usuarios a las aplicaciones.',
      title: 'Todos los usuarios',
    },
    validator: {
      INVALID: 'El valor del campo "{name}" no es válido',
      REQUIRED: 'El campo "{name}" es requerido',
    },
    zone: {
      subtitle: 'Visualizá nuestra zonificación completa.',
      title: 'Todas las zonas',
      update: 'Modificar Zonas',
      autocomplete: 'Seleccioná una de las zonas sugeridas. Si no encontrás la zona que estás buscando, podés ingresar la ciudad y provincia manualmente, pero tené en cuenta que ante algún dato confuso o erróneo el envío puede bloquearse por domicilio icorrecto.',
    },
    crossdock: {
      title: 'Crossdock',
      metric1: 'Total a Entregar',
      metric2: 'Total a Consolidar',
      metric3: 'Total de Bloqueados',
      metric4: 'Total Sin Ingresar',
      action: {
        consolidate: 'Consolidar Envíos',
      },
    },
  },
}

export default {
  locale: 'es-ES',
  localeAntd,
  messages,
}
