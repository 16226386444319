<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <a
        href="https://jipink.com"
        target="_blank"
        rel="noopener noreferrer"
        :class="$style.logo">
        Jipink Desk
      </a>
      <br />
      <p class="my-2">
        Version: <a-tag>{{ version }}</a-tag>
        <span class="text-default mx-1">|</span>
        Revision: <a-tag>{{ revision }}</a-tag>
      </p>
      <p>
        Copyright © {{ new Date().getFullYear() }}.
        <a
          href="https://www.jipink.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-1">
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
    revision() {
      return process.env.VUE_APP_REVISION.substring(0, 8)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
