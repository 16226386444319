import Vue from 'vue'

const getMetrics = (account) => {
  return Vue.prototype.$http.get('metrics', { params: { account } })
}

const getAccounts = () => {
  return Vue.prototype.$http.get('metrics-by-account')
}

export default {
  getMetrics,
  getAccounts,
}
