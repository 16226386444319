<template>
  <a href="javascript: void(0);" @click="switchTheme">
    <a-tooltip placement="bottom">
      <template slot="title">
        <span>{{ $t(`msg.theme.${theme}`) }}</span>
      </template>
      <i v-if="theme === 'default'" class="fe fe-moon" :class="$style.icon"/>
      <i v-if="theme !== 'default'" class="fe fe-sun"  :class="$style.icon"/>
    </a-tooltip>
  </a>
</template>

<script>
export default {
  computed: {
    theme() {
      return this.$store.state.settings.theme
    },
  },
  methods: {
    switchTheme() {
      const nextTheme = (this.theme === 'default') ? 'dark' : 'default'
      this.$store.commit('SET_THEME', { theme: nextTheme })
      this.$store.commit('CHANGE_SETTING', {
        setting: 'menuColor',
        value: nextTheme === 'dark' ? 'dark' : 'light',
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
