import Vue from 'vue'

const create = (courier) => {
  return Vue.prototype.$http.post('couriers', courier)
}

const update = (courier) => {
  return Vue.prototype.$http.put(`couriers/${courier.id}`, courier)
}

const search = (query) => {
  const params = { q: query }
  return Vue.prototype.$http.get('couriers', { params })
}

const fetchAll = (pagination) => {
  const params = pagination
  return Vue.prototype.$http.get('couriers', { params })
}

const fetchOne = (id) => {
  return Vue.prototype.$http.get(`couriers/${id}`)
}

const fetchWaybill = (id) => {
  return Vue.prototype.$http.get(`couriers/${id}/waybill`)
}

const fetchLabels = (id) => {
  return Vue.prototype.$http.post(`couriers/${id}/labels`)
}

const activate = (courierId) => {
  return Vue.prototype.$http.post(`couriers/${courierId}/activate`)
}

const deactivate = (courierId) => {
  return Vue.prototype.$http.post(`couriers/${courierId}/deactivate`)
}

export default {
  create,
  update,
  search,
  fetchAll,
  fetchOne,
  fetchLabels,
  fetchWaybill,
  activate,
  deactivate,
}
