import Vue from 'vue'

import { flatQueryParams } from '@/utils/requests'

const search = (filters, pagination) => {
  const params = flatQueryParams(filters)
  if (pagination) {
    params.page = pagination.page
    params.size = pagination.size
  }
  return Vue.prototype.$http.get('shipments', { params })
}

const fetchDetail = (shipmentId) => {
  return Vue.prototype.$http.get(`shipments/${shipmentId}`)
}

const fetchEvents = (shipmentId) => {
  return Vue.prototype.$http.get(`shipments/${shipmentId}/events`)
}

const fetchItems = (shipmentId) => {
  return Vue.prototype.$http.get(`shipments/${shipmentId}/items`)
}

const fetchLinks = (shipmentId) => {
  return Vue.prototype.$http.get(`shipments/${shipmentId}/links`)
}

const fetchLog = (shipmentId) => {
  return Vue.prototype.$http.get(`shipments/${shipmentId}/log`)
}

const create = (shipment) => {
  if (Array.isArray(shipment)) {
    return Vue.prototype.$http.post('shipments?bulk', shipment)
  }
  return Vue.prototype.$http.post('shipments', shipment)
}

const update = (shipmentId, shipment) => {
  return Vue.prototype.$http.put(`shipments/${shipmentId}`, shipment)
}

const promote = (shipmentId, action, body) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/events?action=${action}`, body)
}

const clone = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/clone`)
}

const recover = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/recover`)
}

const exchange = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/exchange`)
}

const unblock = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/unblock`)
}

const cancel = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/cancel`)
}

const revert = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/revert`)
}

const report = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/report`)
}

const outsource = (shipmentId) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/outsource`)
}

const block = (shipmentId, issue) => {
  return Vue.prototype.$http.post(`shipments/${shipmentId}/block?issue=${issue}`)
}

export default {
  search,
  fetchDetail,
  fetchEvents,
  fetchItems,
  fetchLinks,
  fetchLog,
  create,
  update,
  promote,
  clone,
  recover,
  exchange,
  unblock,
  cancel,
  revert,
  report,
  outsource,
  block,
}
