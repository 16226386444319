<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import Localization from '@/localization'

export default {
  name: 'app',
  components: { Localization },
  computed: {
    ...mapState(['settings']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  mounted() {
    this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
  },
  watch: {
    '$auth.isAuthenticated'(authenticated) {
      if (authenticated) {
        // this.$store.dispatch('GET_METADATA')
      }
    },
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === '/auth/login') {
        this.$router.replace(this.nextRoute)
      }
    },
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  metaInfo () {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,
          async: true,
          defer: true,
          callback: () => this.onGooglePlacesLoad(),
        },
      ],
    }
  },
  methods: {
    onGooglePlacesLoad () {
      Vue.prototype.$geocoder = new window.google.maps.Geocoder()
      Vue.prototype.$autocomplete = new window.google.maps.places.AutocompleteService()
    },
  },
}
</script>
