import Moment from 'moment'
import isObject from 'lodash/isObject'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

export const flatQueryParams = (obj = {}, res = {}, extraKey = '') => {
  for (const key in obj) {
    if (!isObject(obj[key]) && obj[key]) {
      res[camelCase(extraKey + key)] = obj[key]
    } else if (obj[key] instanceof Moment) {
      res[camelCase(extraKey + key)] = obj[key].format()
    } else if (Array.isArray(obj[key]) && obj[key].length) {
      res[camelCase(extraKey + key)] = obj[key].join()
    } else {
      flatQueryParams(obj[key], res, `${extraKey}${key}_`)
    }
  }
  return res
}

export const flatJsonFields = (obj = {}, res = {}, extraKey = '') => {
  for (const key in obj) {
    if (!isObject(obj[key]) && obj[key]) {
      res[snakeCase(extraKey + key)] = obj[key]
    } else if (obj[key] instanceof Moment) {
      res[snakeCase(extraKey + key)] = obj[key].format()
    } else if (Array.isArray(obj[key]) && obj[key].length) {
      res[snakeCase(extraKey + key)] = obj[key]
    } else {
      flatJsonFields(obj[key], res, `${extraKey}${key}_`)
    }
  }
  return res
}
