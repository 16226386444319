import Vue from 'vue'

import { flatQueryParams } from '@/utils/requests'

const download = (filters, printer) => {
  const flatten = flatQueryParams(filters)
  const params = { printer, ...flatten }
  return Vue.prototype.$http.get('labels', { params })
}

export default {
  download,
}
