<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" :src="$auth.user.picture"/>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <div class="text-dark">
          <strong>{{ $t('hello') }}, {{ $auth.user.name || 'Anonymous' }}</strong>
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('email') }}:</strong>
          {{ $auth.user.email || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="$auth.logout()">
          <i class="fe fe-lock mr-2"></i>
          {{ $t('msg.usermenu.password') }}
        </a>
      </a-menu-item>
      <a-menu-item>
        <a href="https://academy.jipink.com" target="_blank">
          <i class="fe fe-help-circle mr-2"></i>
          {{ $t('msg.usermenu.academy') }}
        </a>
      </a-menu-item>
      <a-menu-item>
        <a href="https://forms.gle/7hJrJUhAHkdTjod89" target="_blank">
          <i class="fe fe-thumbs-up mr-2"></i>
          {{ $t('msg.usermenu.feedback') }}
        </a>
      </a-menu-item>
      <a-menu-item>
        <router-link to="/troubleshoot">
          <i class="fe fe-activity mr-2"></i>
          {{ $t('msg.usermenu.troubleshoot') }}
        </router-link>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a class="text-danger" href="javascript: void(0);" @click="$auth.logout()">
          <i class="fe fe-log-out mr-2"></i>
          {{ $t('logout') }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      count: 7,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
