import Vue from 'vue'

const fetch = () => {
  return Vue.prototype.$http.get('/users/me')
}

const create = (user) => {
  return Vue.prototype.$http.post('users', user)
}

const update = (user) => {
  return Vue.prototype.$http.put(`users/${user.id}`, user)
}

const remove = (user) => {
  return Vue.prototype.$http.delete(`users/${user.id}`)
}

const search = (query) => {
  const params = { q: query }
  return Vue.prototype.$http.get('users', { params })
}

const fetchAll = (pagination) => {
  const params = pagination
  return Vue.prototype.$http.get('users', { params })
}

const fetchOne = (id) => {
  return Vue.prototype.$http.get(`users/${id}`)
}

export default {
  fetch,
  create,
  update,
  search,
  remove,
  fetchAll,
  fetchOne,
}
