<template>
  <div
    class="text-center main"
    v-if="offline">
    <a-alert
      type="error"
      class="text-left mb-4"
      :message="$t('msg.offline.title')"
      :description="$t('msg.offline.description')"
      show-icon>
      <a-icon
        slot="icon"
        type="api" />
      <a-button
        slot="closeText"
        type="link"
        icon="reload"
        @click="() => $router.go()">
        {{ $t('retry') }}
      </a-button>
    </a-alert>
  </div>
</template>
<script>
export default {
  name: 'Alert',
  computed: {
    offline() {
      return !this.$store.getters['session/ONLINE']
    },
  },
}
</script>
