import Vue from 'vue'
import Vuex from 'vuex'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

Vue.use(Vuex)

export function DefaultFilters() {
  return {
    sort: 'NEWEST',
    method: [],
    status: [],
    substatus: [],
    platform: [],
    account: [],
    courier: [],
    route: [],
    shortcut: undefined,
    date: {
      type: 'CREATED',
      key: 'LAST_MONTH',
      range: {
        min: undefined,
        max: undefined,
      },
    },
  }
}

export default {
  namespaced: true,
  state: {
    query: null,
    results: {
      data: [],
      items: 0,
    },
    pagination: {
      page: 1,
      size: 30,
    },
    filters: DefaultFilters(),
  },
  mutations: {
    SET_RESULTS(state, payload) {
      state.results = payload
    },
    SET_RESULT(state, payload) {
      const index = state.results.data.findIndex(f => f.id === payload.id)
      Vue.set(state.results.data, index, payload)
    },
    SET_QUERY(state, payload) {
      state.query = payload
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload
    },
    SET_FILTERS(state, payload) {
      state.query = null
      state.filters = { ...state.filters, ...payload }
      state.pagination.page = 1
    },
    PUT_FILTERS(state, payload) {
      state.query = null
      state.filters = { ...DefaultFilters(), ...payload }
      state.pagination.page = 1
    },
    CLEAR_FILTERS(state) {
      state.query = null
      state.filters = { ...DefaultFilters() }
      state.pagination.page = 1
    },
  },
  getters: {
    RESULTS: state => state.results,
    QUERY: state => state.query,
    FILTERS: state => state.filters,
    PAGINATION: state => state.pagination,
    IS_FILTERED: state => {
      const o1 = state.filters
      const o2 = DefaultFilters()
      return !isEqual(
        omit(o1, ['date.key']),
        omit(o2, ['date.key']),
      )
    },
  },
}
