export default {
  metrics: {
    general: ['admin.shipments.read', 'shipments.read'],
    account: ['admin.shipments.read'],
  },
  accounts: {
    search: ['admin.accounts.read'],
    detail: ['admin.accounts.read'],
    create: ['admin.accounts.write'],
    update: ['admin.accounts.write'],
    delete: ['admin.accounts.write'],
    mock: ['admin.accounts.write'],
  },
  shipments: {
    monitor: ['admin.shipments.read'],
    search: ['admin.shipments.read', 'shipments.read'],
    detail: ['admin.shipments.read', 'shipments.read'],
    export: ['admin.shipments.write', 'admin.exports.write', 'exports.write'],
    print: ['admin.shipments.read', 'shipments.read'],
    items: ['admin.items.read', 'items.read'],
    create: ['admin.shipments.write', 'shipments.write'],
    update: ['admin.shipments.write', 'shipments.write'],
    cancel: ['admin.shipments.write', 'shipments.write'],
    track: ['admin.events.read', 'events.read'],
    promote: ['admin.events.write'],
  },
  couriers: {
    search: ['admin.couriers.read'],
    create: ['admin.couriers.write'],
    update: ['admin.couriers.write'],
    delete: ['admin.couriers.write'],
  },
  templates: {
    search: ['admin.templates.read', 'templates.read'],
    create: ['admin.templates.write', 'templates.write'],
    update: ['admin.templates.write', 'templates.write'],
    delete: ['admin.templates.write', 'templates.write'],
  },
  stores: {
    search: ['admin.stores.read', 'stores.read'],
    create: ['admin.stores.write', 'stores.write'],
    update: ['admin.stores.write', 'stores.write'],
    delete: ['admin.stores.write', 'stores.write'],
  },
  shops: {
    admin: ['admin.shops.read'],
    search: ['shops.read'],
    create: ['shops.write'],
    update: ['shops.write'],
    delete: ['shops.write'],
  },
  rates: {
    search: ['admin.rates.read'],
    create: ['admin.rates.write'],
    update: ['admin.rates.write'],
    delete: ['admin.rates.write'],
  },
  tokens: {
    search: ['admin.tokens.read', 'tokens.read'],
    create: ['admin.tokens.write', 'tokens.write'],
    update: ['admin.tokens.write', 'tokens.write'],
  },
  payments: {
    search: ['admin.payments.read'],
    update: ['admin.payments.write'],
  },
  zones: {
    search: ['admin.zones.read'],
  },
  routes: {
    search: ['admin.couriers.read'],
  },
  crossdock: {
    search: ['admin.shipments.read'],
  },
  tasks: {
    search: ['admin.tasks.read'],
    create: ['admin.tasks.write'],
  },
  waybills: {
    search: ['apps.xd'],
  },
  simulator: {
    search: ['rates.read'],
  },
  settings: {
    detail: ['accounts.read'],
    update: ['accounts.write'],
  },
  exports: {
    create: ['admin.exports.write'],
  },
  users: {
    search: ['admin.users.read'],
    create: ['admin.users.write'],
    update: ['admin.users.write'],
    delete: ['admin.users.write'],
  },
}
