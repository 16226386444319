import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  account: 'Account',
  accountId: 'Account ID',
  accounts: 'Accounts',
  actions: 'Actions',
  active: 'Active',
  address: 'Address',
  admin: 'Admin',
  and: 'and',
  anonymous: 'Anonymous',
  apartment: 'Floor / Apartment',
  apiKey: 'API Key',
  apply: 'Apply',
  author: 'Author',
  available: 'Available',
  back: 'Back',
  before: 'Before',
  cancel: 'Cancel',
  city: 'City',
  clear: 'Clear',
  code: 'Code',
  company: 'Company',
  confirm: 'Confirm',
  connect: 'Connect',
  contact: 'Contact',
  continue: 'Continue',
  courier: 'Courier',
  couriers: 'Couriers',
  courierType: 'Courier Type',
  create: 'Create',
  created: 'Created',
  crossdock: 'Crossdock',
  currency: 'Currency',
  cutoff: 'Cutoff',
  dashboard: 'Dashboard',
  date: 'Date',
  dateMin: 'Date Min',
  dateMax: 'Date Max',
  delayed: 'Delayed',
  delete: 'Delete',
  delivery: 'Delivery',
  destination: 'Destination',
  detail: 'Detail',
  dispatched: 'Dispatched',
  edit: 'Edit',
  edited: 'Edited',
  email: 'Email',
  events: 'Events',
  exchange: 'Exchange',
  expiration: 'Expiration',
  expired: 'Expired',
  expires: 'Expires',
  export: 'Export',
  exported: 'Exported',
  exports: 'Reports',
  extraMin: 'Min Extra Days',
  extraMax: 'Max Extra Days',
  filter: 'Filter',
  filtered: 'Filtered',
  finish: 'Finish',
  format: 'Format',
  form: 'Form',
  general: 'General',
  hello: 'Hello',
  history: 'History',
  identification: 'Identification',
  import: 'Import',
  issue: 'Issue',
  labels: 'Labels',
  leadTime: 'Lead Time',
  loading: 'Loading',
  locality: 'Town / Locality',
  logout: 'Logout',
  main: 'Main',
  method: 'Method',
  metrics: 'Metrics',
  mock: 'Mock',
  name: 'Name',
  new: 'New',
  next: 'Next',
  noname: 'No Name',
  now: 'Now',
  no: 'No',
  notes: 'Notes',
  operation: 'Operation',
  order: 'Order',
  origin: 'Origin',
  outsource: 'Outsource',
  of: 'of',
  package: 'Package',
  packages: 'Packages',
  payment: 'Payment',
  payments: 'Payments',
  phone: 'Phone',
  platform: 'Source',
  platforms: 'Platforms',
  preview: 'Preview',
  print: 'Print',
  printer: 'Printer',
  property: 'Property',
  rate: 'Rate',
  rates: 'Rates',
  receiver: 'Receiver',
  ref: 'Reference ID',
  refresh: 'Refresh',
  region: 'Region',
  results: 'Results',
  retry: 'Retry',
  return: 'Return',
  role: 'Role',
  roles: 'Roles',
  route: 'Route',
  routes: 'Routes',
  scope: 'Scope',
  search: 'Search',
  select: 'Select',
  sender: 'Sender',
  settings: 'Settings',
  shipment: 'Shipment',
  shipments: 'Shipments',
  shop: 'Shop',
  shops: 'Shops',
  simulator: 'Simulator',
  size: 'Size',
  sort: 'Order',
  state: 'Province',
  status: 'Status',
  store: 'Store',
  stores: 'Stores',
  street: 'Street & Number',
  substatus: 'Sub-Status',
  support: 'Support',
  tasks: 'Tasks',
  template: 'Link',
  templates: 'Templates',
  to: 'to',
  tracking: 'Tracking',
  type: 'Type',
  token: 'Token',
  tokens: 'Tokens',
  troubleshooter: 'Diagnóstico',
  unknown: 'Unknown',
  unscheduled: 'Unscheduled',
  update: 'Edit',
  updated: 'Edited',
  user: 'Usuario',
  users: 'Users',
  value: 'Value',
  vehicle: 'Vehicle',
  volume: 'Volume',
  waybills: 'Waybills',
  yes: 'Yes',
  zip: 'Postal Code',
  zone: 'Zone',
  zones: 'Zones',
  enum: {
    activation: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      PENDING: 'Pending',
    },
    collect: {
      ON_DEMAND: 'On Demand',
      STANDARD: 'Standard',
    },
    courierType: {
      COMPANY: 'Company',
      SINGLE: 'Single',
    },
    csv: {
      WIN: 'Jipink (Windows)',
      MAC: 'Jipink (Mac)',
      SHF: 'Shopify',
      MAG: 'Magento',
    },
    currency: {
      ARS: 'ARS (Peso Argentino)',
      USD: 'USD (Dólar Estadounidense)',
    },
    dateKey: {
      LAST_DAY: 'Last 24 hs',
      LAST_WEEK: 'Last week',
      LAST_MONTH: 'Last month',
      LAST_SIX_MONTHS: 'Last 6 months',
      LAST_YEAR: 'Last year',
    },
    dateType: {
      CREATED: 'Created',
      UPDATED: 'Updated',
      PROMOTED: 'Last State',
      DISPATCHED: 'Dispatched',
    },
    direction: {
      BACKWARD: 'Backward',
      FORWARD: 'Forward',
    },
    flex: {
      FLEX_NOT_PICKED: 'Not picked',
      FLEX_NOT_MARKED: 'Not marked',
      FLEX_NOT_DELIVERED: 'Not delivered',
    },
    issue: {
      ABSENT_RECEIVER: 'Absent receiver',
      BROKEN: 'Broken',
      BUSINESS_HOURS: 'Business hours',
      CANCELED: 'Canceled',
      DUPLICATED: 'Duplicated',
      INCORRECT_ADDRESS: 'Incorrect address',
      LOST: 'Lost',
      NOT_VISITED: 'Not visited',
      ON_VACATIONS: 'On vacations',
      OVERSIZE: 'Oversize',
      REJECTED: 'Rejected',
      RESCHEDULED: 'Rescheduled',
      STOLEN: 'Stolen',
      UNREACHABLE_AREA: 'Unreachable area',
      UNREACHABLE_RECEIVER: 'Unreachable receiver',
      VEHICLE_ISSUE: 'Vehicle issue',
      WRONG_PRODUCT: 'Wrong product',
      EXHAUSTED: 'Max attempts reached',
    },
    method: {
      EXPRESS: 'Express',
      STANDARD: 'Standard',
      P2P: 'P2P',
    },
    payment: {
      APPROVED: 'Approved',
      PENDING: 'Pending',
      PROCESSING: 'Processing',
      REJECTED: 'Rejected',
    },
    plan: {
      BRONZE: 'Bronze',
      SILVER: 'Silver',
      GOLD: 'Gold',
      PLATINUM: 'Platinum',
      BLACK: 'Black',
    },
    printer: {
      PDF_A4: 'PDF A4 (210 x 297 mm) - 8 LPP',
      PDF_A7: 'PDF A7 (74 x 105 mm) - 1 LPP',
      ZPL: 'ZPL (100 x 150 mm)',
    },
    sort: {
      NEWEST: 'Newest',
      OLDEST: 'Oldest',
    },
    size: {
      S: 'S - Max 5 kg',
      M: 'M - Max 10 kg',
      L: 'L - Max 20 kg',
      XL: 'XL - Max 40 kg',
    },
    source: {
      JPK: 'Jipink',
      OMN: 'Omnichannel',
      MLI: 'MercadoLibre',
      P2P: 'P2P',
      TNB: 'Tiendanube',
      VTX: 'Vtex',
      WOO: 'WooCommerce',
      SHF: 'Shopify',
    },
    status: {
      ABORTED: 'Aborted',
      ADMITTED: 'In office',
      BLOCKED: 'Blocked',
      CANCELED: 'Canceled',
      COLLECTED: 'Collected',
      CONFIRMED: 'Confirmed',
      CONSOLIDATED: 'Consolidated',
      DELIVERED: 'Delivered',
      DISPATCHED: 'Dispatched',
      EXPIRED: 'Expired',
      LOST: 'Lost',
      NOT_VISITED: 'Not visited',
      OUT_FOR_DELIVERY: 'Out for delivery',
      OUT_FOR_RETURN: 'Out for return',
      OUT_FOR_RECOVERY: 'Out for recovery',
      OUT_FOR_COLLECTION: 'Out for collect',
      PICK_UP: 'Pick-Up',
      RECEIVED: 'Received',
      RECOVERED: 'Recovered',
      RETURNED: 'Returned',
      STOLEN: 'Stolen',
      TO_APPROVE: 'To approve',
      TO_COLLECT: 'To collect',
      TO_CONSOLIDATE: 'To consolidate',
      TO_DELIVER: 'To deliver',
      TO_RECEIVE: 'To receive',
      TO_RECOVER: 'To recover',
      TO_RETURN: 'To return',
      VISITED: 'Visited',
    },
    type: {
      DELIVERY: 'Delivery',
      EXCHANGE: 'Exchange',
      RETURN: 'Return',
    },
    vehicle: {
      BIKE: 'Bike',
      CAR: 'Car',
      MOTORBIKE: 'Motorbike',
      VAN: 'Van',
    },
  },
  msg: {
    abm: {
      action: {
        create: 'Create',
        update: 'Modify',
      },
      delete: {
        alert: 'Are you sure you want to delete? ',
        subtitle: 'Once you remove it, there will be no going back.',
        title: 'Delete',
      },
    },
    account: {
      action: {
        activate: 'Activate Account',
        deactivate: 'Deactivate Account',
        edit: 'Edit Data',
        mock: 'Operate Account',
        rate: 'Edit Rates',
        store: 'Edit Stores',
        shop: 'View Shops',
        template: 'Edit Links',
        troubleshoot: 'Troubleshoot',
        token: 'Edit Tokens',
        user: 'Activate User',
      },
      activated: 'The account was activated',
      deactivated: 'The account was deactivated',
      granted: 'The user was activated',
      name: 'Accounts',
      subtitle: 'Manage the accounts that operate with the platform.',
      title: 'All Accounts',
    },
    action: {
      APPROVE: 'Operation approved',
      CANCEL: 'Operation canceled',
      COLLECT: 'Successful collection reported',
      CONFIRM: 'Prompt visit confirmed',
      CONSOLIDATE: 'Package consolidated',
      DISPATCH: 'Dispatched to carrier',
      DROP: 'Carrier unassigned',
      HIT: 'Successful visit reported',
      MISS: 'Incident reported',
      RECALL: 'Dispatched to carrier',
      RECEIVE: 'Received at warehouse',
      RETURN: 'Successful return reported',
      BLOCK: 'Operation blocked',
      UNBLOCK: 'Operation unblocked',
      ADMIT: 'Received at post office',
      PICK_UP: 'Received at a pick-up point',
      EXPIRE: 'Deadline expired',
    },
    banner: {
      title: 'Need help?',
      subtitle: 'Please check our docs',
      action: 'Documentation',
    },
    copied: 'Copied to your clipboard',
    log: {
      title: 'Changelog',
      empty: 'There is no changes',
    },
    courier: {
      action: {
        activate: 'Activate Messenger',
        deactivate: 'Deactivate Messenger',
        labels: 'Dispatch Labels',
        waybill: 'Print Waybill',
        edit: 'Edit Data',
      },
      activated: 'The messenger was activated',
      deactivated: 'The messenger was deactivated',
      name: 'Messengers',
      subtitle: 'Manage the carriers and couriers that distribute the parcel.',
      title: 'All Messengers',
    },
    flag: {
      ERROR: 'This event was discarded because it was an error',
      FRAUD: 'Possible fraud was reported on this event',
    },
    error: {
      internal: 'Internal server error :(',
    },
    exports: {
      subtitle: 'Get the reports you need.',
      title: 'Your reports',
      message: 'The report will be sent to',
      submit: 'Create Report',
    },
    issue: {
      ABSENT_RECEIVER: 'No one was found at the address',
      BROKEN: 'The package is broken and cannot be delivered',
      BUSINESS_HOURS: 'The address is only open during business hours',
      CANCELED: 'The operation was canceled before being completed',
      DUPLICATED: 'The operation has already been performed and is duplicated',
      INCORRECT_ADDRESS: 'The address is incorrect or does not exist',
      LOST: 'The carrier reported the loss of the packages',
      NOT_VISITED: 'The carrier was unable to visit the address',
      ON_VACATIONS: 'The address is closed for vacations',
      OVERSIZE: 'The package is too big or heavy to be transported',
      REJECTED: 'The operation was rejected at home',
      RESCHEDULED: 'The operation was rescheduled for a new date',
      STOLEN: 'The carrier reported the packages stolen',
      UNREACHABLE_AREA: 'The address is in an area of ​​difficult access',
      UNREACHABLE_RECEIVER: 'The phone is wrong or no one is answering',
      VEHICLE_ISSUE: 'The carrier had a problem with his vehicle',
      WRONG_PRODUCT: 'The product is incorrect',
      EXHAUSTED: 'The delivery attempts have been exhausted',
    },
    item: {
      subtitle: 'These are the items related to the shipment.',
      title: 'All the items',
    },
    metrics: {
      banner: {
        top: 'Jipink Academy',
        title: 'Take your shipping to the next level',
        subtitle: 'Find tutorials on how to operate with our platform, guides to integrate your store, and much more on our new documentation site.',
        action: 'Learn More',
      },
      counters: {
        active: 'Active',
        blocked: 'Blocked',
        delayed: 'Delayed',
        pending: 'Pending',
      },
      dv: {
        title: 'Today\'s total',
        tooltip: 'Compared to the previous day',
      },
      lt: {
        title: 'Lead Time',
      },
      mh: {
        title: 'Monthly Volume',
        total: 'Volume',
        subtitle: 'compared to previous month',
        empty: 'We are still collecting data :)',
      },
      mv: {
        title: 'Total for the month',
        tooltip: 'Compared to the previous month',
      },
      su: {
        title: 'Success',
      },
    },
    mock: {
      description: 'You are operating as {name}. Everything you do will be processed as if it were done by this account.',
    },
    offline: {
      description: 'We were unable to connect to our servers, this could be due to a connection problem with your network.',
      title: 'No Connection',
    },
    usermenu: {
      academy: 'Support',
      password: 'Change password',
      feedback: 'Feedback',
      troubleshoot: 'Troubleshoot',
    },
    password: {
      change: 'Change password',
    },
    payment: {
      action: {
        receipt: 'View Receipt',
      },
      approved: 'Paid',
      name: 'Payments',
      pending: 'Pending',
      subtitle: 'Manage the payments made through the enabled means.',
      title: 'All Payments',
    },
    platform: {
      action: {
        delete: 'Disconnect',
        health: 'Test Connection',
        settings: 'Settings',
      },
      confirm: {
        delete: 'We will stop receiving shipments from this store.',
      },
      name: 'Stores',
      new: {
        subtitle: 'Connect your store in a few steps using our available integrations.',
        title: 'Connect Store',
      },
      subtitle: 'These are the stores you connected to our platform.',
      title: 'Your Stores',
    },
    promote: {
      title: 'Report Event',
      subtitle: 'Report news of shipments that the carrier has not reported correctly. ',
      submit: 'Report',
      actions: {
        HIT: 'Done',
        MISS: 'There was an issue',
      },
    },
    rate: {
      action: {
        edit: 'Edit Rate',
      },
      name: 'Rates',
      subtitle: 'Manage fees and services for an account.',
      title: 'Your Rates',
    },
    route: {
      labels: 'Print Labels',
      subtitle: 'Display the volume of shipments for each route.',
      title: 'All routes',
      waybills: 'Print Waybill',
    },
    settings: {
      subtitle: 'Manage your account settings.',
      title: 'Your settings',
    },
    shipment: {
      packages: '{count} package(s)',
      assignedTo: 'Assigned to courier',
      createdBy: 'Registered by',
      updatedBy: 'Updated by',
      action: {
        block: 'Block Send',
        cancel: 'Cancel Shipment',
        clone: 'Clone Shipment',
        revert: 'Revertir Estado',
        report: 'Report Fraud',
        promote: 'Report Event',
        edit: 'Edit Shipping',
        exchange: 'Start Exchange',
        outsource: 'Redispatch',
        printOne: 'Print Label',
        printFlex: 'Print Flex',
        printOutsource: 'Print Outsource',
        recover: 'Start Return',
        unlock: 'Retry',
        view: 'View Detail',
        viewCourier: 'View Courier',
        viewItems: 'View Items',
      },
      blocked: {
        subtitle: 'We detected a problem with this operation, and we cannot proceed until you take action.',
        title: 'Why is it blocked?',
        unlock: {
          subtitle: 'Make sure the reported problem is solved before continuing with this operation, to avoid incurring extra costs.',
          title: 'Do you want to unlock the operation?',
        },
      },
      confirm: {
        block: 'The operation will be blocked, and the creator of the operation will be requested to solve the problem.',
        clone: 'A new shipment will be created in our platform with the same data as the referenced shipment.',
        outsource: 'A new shipment will be created at our logistics partner, and you will have to print the redispatch label.',
        revert: 'The last status will be reverted to the previous one.',
        report: 'The shipment will be marked as possible fraud by the assigned courier.',
        cancel: 'This operation will cancel the shipment permanently. Are you sure to continue?',
        exchange: 'A new shipment will be created to initiate the exchange. At the time of delivering the new shipment, the corresponding packages will be removed and returned to your address.',
        recover: 'We will pick up the packages from the recipient\'s address, and send them back to your address.',
      },
      create: {
        action: 'New Shipment',
        single: {
          title: 'Simple Load',
          subtitle: 'Complete the data, or import it from your store.',
          section1: 'Type of operation',
          section2: 'Delivery details',
          delivery: 'We collect the packet at the origin, and send it to the destination.',
          return: 'We retrieve the previously delivered package from the destination.',
          exchange: 'We send the package to the destination, in exchange of a return.',
        },
        multi: {
          title: 'Multi Load',
          subtitle: 'Upload multiple shipments from a CSV file.',
          section1: 'File Upload',
          upload: {
            title: 'Click or drag the file here',
            subtitle1: 'Need an example file?',
            subtitle2: 'Use the CSV exported from your store',
            action1: 'Download for Windows',
            action2: 'Download for Mac',
          },
        },
      },
      export: {
        title: 'Export Shipments',
        subtitle1: 'You are exporting a total of:',
        subtitle2: 'Once the export is ready, we are sending the file to:',
        warning: 'Max limit of {limit} shipments reached',
      },
      exported: {
        action1: 'Back to Shipping',
        subtitle: 'When the file is ready, we will email it to you so you can download it.',
        title: 'We are exporting your shipments',
      },
      filters: {
        active: 'Only the active ones',
        blocked: 'Only the blocked ones',
        delayed: 'Only delayed ones',
        notMarked: 'Only unmarked',
        pending: 'Only pending',
      },
      flex: {
        action: 'View Label',
        marked: 'Reported with the Flex APP',
        picked: 'Scanned with the Flex APP',
        delivered: 'Delivered with the Flex APP',
        title: 'Flex Shipping Market',
      },
      imported: 'The shipment was imported',
      label: {
        pending: 'Not printed',
        printed: 'Printed',
      },
      mode: 'Load Type',
      multi: {
        request: 'Request Shipments',
      },
      print: {
        title: 'Print Labels',
        subtitle1: 'You are printing a total of:',
        subtitle2: 'We will use the following format:',
        subtitle3: 'Based on the filters you applied to your submissions.',
        warning: 'Maximum of {limit} labels exceeded',
      },
      related: {
        detail: 'Shipments that are related to the current one.',
        title: 'Related',
      },
      search: 'What shipment are you looking for?',
      selected: {
        title: 'selected',
        print: 'Print Labels',
        printFlex: 'Print Flex',
        clear: 'Unselect All',
      },
      single: {
        request: 'Request Shipping',
      },
      success: {
        action1: 'View Shipment',
        action2: 'Back to Shipping',
        cancel: 'Send cancelled!',
        clone: 'Shipment cloned!',
        create: 'Delivery created!',
        exchange: 'Exchange initiated!',
        recover: 'Return started!',
        revert: 'Status reverted!',
      },
      update: 'Save Changes',
    },
    shop: {
      action: {
        connect: 'Connect',
        tutorial: 'Go to Tutorial',
        disconnect: 'Disconnect',
        reconnect: 'Reconnect',
        configure: 'Configure',
        update: 'Rename',
      },
      callback: {
        failure: {
          action1: 'Retry',
          action2: 'Go to Stores',
          subtitle: 'Your store could not be integrated into our platform.',
          title: 'Integration failed',
        },
        processing: {
          subtitle: 'Please wait a moment while we complete the integration.',
          title: 'Processing...',
        },
        success: {
          action: 'Go to Stores',
          subtitle: 'Your store is already integrated into our platform.',
          title: 'Connected store!',
        },
      },
      flex: {
        title: 'MercadoLibre',
        subtitle: 'Configure all key aspects of your Flex shipments.',
        week: 'Monday to Friday',
        saturday: 'Saturdays',
        sunday: 'Sundays',
        enable: 'Enable Mercado Envíos Flex',
        cutoff: 'Cut-off Time',
        capacity: 'Shipment Limit',
        zones: 'Coverage Zones',
        min: 'Minimum Delivery Time',
        max: 'Maximum Delivery Time',
        submit: 'Save Changes',
      },
      update: {
        title: 'Modify Shop',
        subtitle: 'Modify the properties of your shop.',
        name: 'Name',
        sender: 'Use this name as the sender of the shipments',
        submit: 'Save Changes',
      },
    },
    simulator: {
      calendar: 'Between',
      methods: 'Available Methods',
      placeholder: 'Enter the zip code to quote',
      submit: 'Quote',
      subtitle: 'Quote a shipment by postal code',
      title: 'Quoter',
    },
    status: {
      ABORTED: 'The operation was canceled and it will be interrupted',
      BLOCKED: 'The operation cannot continue and client intervention is required',
      CANCELED: 'The operation was cancelled',
      COLLECTED: 'Packages were removed from origin',
      CONFIRMED: 'The address will be visited soon by the carrier',
      CONSOLIDATED: 'The package was successfully consolidated into a parent package',
      DELIVERED: 'The packages were successfully delivered to the recipient',
      DISPATCHED: 'A carrier has been assigned, and the address is already part of its route',
      EXPIRED: 'The package was successfully received at the mail distribution center',
      ADMITTED: 'The package was successfully received at the mail distribution center',
      FETCHED: 'Packages were removed from origin',
      LOST: 'The packages were lost and we must collect again',
      NOT_VISITED: 'The address has not been visited yet',
      PICK_UP: 'The package is in the office ready for pick-up.',
      RECOVERED: 'Packages were removed from destination',
      RETURNED: 'Packages were successfully returned to origin',
      STOLEN: 'The packages were stolen and we will have to collect again',
      TO_APPROVE: 'The operation has not yet been confirmed and is on hold',
      TO_COLLECT: 'You entered the request to make the shipment',
      TO_CONSOLIDATE: 'The package is in the distribution center awaiting consolidation',
      TO_DELIVER: 'The packages were received at the distribution center',
      TO_RECOVER: 'You entered the request to return the shipment',
      TO_RETURN: 'Packets will be dispatched back to origin',
      TO_RECEIVE: 'Waiting to receive the package in the post office',
      RECEIVED: 'The package was received in the post office',
      VISITED: 'The address was visited correctly, but the delivery could not be made',
    },
    store: {
      action: {
        edit: 'Edit Warehouse',
      },
      main: {
        false: 'Secondary Warehouse',
        help: 'A main warehouse is where most of your shipments are collected.',
        true: 'Main Warehouse',
      },
      name: 'Warehouses',
      subtitle: 'Add the deposits or branches that you want to use as collection points.',
      title: 'Your Warehouses',
    },
    task: {
      subtitle: 'These are all the tasks scheduled for execution.',
      title: 'All tasks',
      executed: 'Last execution',
      scheduled: 'Next execution',
      unexecuted: 'There has been no execution yet',
      unscheduled: 'There is no scheduled next execution',
    },
    template: {
      action: {
        edit: 'Edit Link',
      },
      expired: {
        false: 'Expires',
        true: 'Expired',
      },
      everlasting: 'No expiration',
      help: 'If enabled, the user must pay the shipment with MercadoPago.',
      name: 'Links',
      payment: {
        false: 'Do not request payment',
        true: 'Request payment',
      },
      subtitle: 'Create links so that your clients or branches can upload shipments associated with your account.',
      title: 'Your Self-Management Links',
    },
    theme: {
      dark: 'Dark Mode',
      default: 'Clear Mode',
    },
    token: {
      name: 'Tokens',
      subtitle: 'Create credentials for third-party applications so they can access your account.',
      title: 'Your Tokens',
    },
    troubleshoot: {
      title: 'Troubleshoot',
      subtitle: 'We will try to detect technical problems in your configuration and/or integrations.',
      run: 'Start Troubleshoot',
      retry: 'Restart',
      fix: 'Fix',
    },
    undefined: 'No {name}',
    user: {
      name: 'Users',
      subtitle: 'Manage the access users have to applications.',
      title: 'All the users',
    },
    validator: {
      INVALID: 'The field "{name}" is not valid',
      REQUIRED: 'The field "{name}" is required',
    },
    zone: {
      subtitle: 'View our complete zoning.',
      title: 'All Zones',
      update: 'Modify Zones',
      autocomplete: 'Select one of the suggested areas. If you do not find the area you are looking for, you can enter the city and province manually, but keep in mind that in the event of any confusing or erroneous information, the shipment may be blocked by incorrect address.',
    },
    crossdock: {
      title: 'Crossdock',
      metric1: 'Total Deliver',
      metric2: 'Total Consolidate',
      metric3: 'Total Blocked',
      metric4: 'Total Not Received',
      action: {
        consolidate: 'Consolidate Shipments',
      },
    },
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
