import { getInstance } from './index'

export const guard = (to, from, next) => {
  if (to.meta.authorize) {
    const authService = getInstance()

    const fn = () => {
      // If the user is authenticated and has the authorized role, continue with the route
      if (authService.isAuthenticated) {
        const scopes = (authService.user || {}).scopes || []
        const authorize = to.meta.authorize || []
        if (authorize.some(s => scopes.includes(s))) {
          return next()
        } else {
          return next('/error/403')
        }
      }

      // Otherwise, log in
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
      return fn()
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
      if (loading === false) {
        return fn()
      }
    })
  } else {
    return next()
  }
}
