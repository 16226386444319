import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    payload: {
      lead_time: {
        total: 0,
      },
      daily_volume: {
        total: 0,
        growth: 0,
      },
      monthly_volume: {
        total: 0,
        growth: 0,
      },
      success: {
        total: 0,
        growth: 0,
      },
      count: {
        pending: 0,
        active: 0,
        delayed: 0,
        blocked: 0,
      },
      flex: {
        not_marked: 0,
        not_picked: 0,
        not_delivered: 0,
      },
      history: [],
    },
    updated: null,
  },
  mutations: {
    SET_PAYLOAD(state, payload) {
      state.payload = payload
      state.updated = new Date()
    },
  },
  getters: {
    PAYLOAD: state => state.payload,
    UPDATED: state => state.updated,
  },
}
