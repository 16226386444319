import Vue from 'vue'

const install = (platform, payload) => {
  return Vue.prototype.$http.post(`platforms/${platform}/install`, payload)
}

const uninstall = (platform, shop) => {
  return Vue.prototype.$http.post(`platforms/${platform}/uninstall/${shop}`)
}

const fetchAvailable = () => {
  return Vue.prototype.$http.get('platforms')
}

const fetchManifest = (platform) => {
  return Vue.prototype.$http.get(`platforms/${platform}/manifest`)
}

const fetchTutorial = (platform) => {
  return Vue.prototype.$http.get(`platforms/${platform}/tutorial`)
}

const fetchShipment = (accountId, ref) => {
  return Vue.prototype.$http.get(`platforms/import?account=${accountId}&ref=${ref}`)
}

const fetchLabel = (shopId, id, printer) => {
  const params = { id, printer }
  return Vue.prototype.$http.get(`shops/${shopId}/labels`, { params })
}

const fetchConfiguration = (shopId) => {
  return Vue.prototype.$http.get(`shops/${shopId}/configuration`)
}

const updateConfiguration = (shopId, payload) => {
  return Vue.prototype.$http.post(`shops/${shopId}/configuration`, payload)
}

export default {
  install,
  uninstall,
  fetchAvailable,
  fetchManifest,
  fetchTutorial,
  fetchShipment,
  fetchLabel,
  fetchConfiguration,
  updateConfiguration,
}
