import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    online: true,
    account: undefined,
    user: {
      scopes: [],
    },
  },
  mutations: {
    SET_ONLINE(state) {
      state.online = true
    },
    SET_OFFLINE(state) {
      state.online = false
    },
    SET_ACCOUNT(state, payload) {
      state.account = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
  },
  getters: {
    ONLINE: state => state.online,
    ACCOUNT: state => state.account,
    USER: state => state.user,
  },
}
