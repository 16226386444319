import Vue from 'vue'

const create = (account) => {
  return Vue.prototype.$http.post('accounts', account)
}

const update = (account) => {
  return Vue.prototype.$http.put(`accounts/${account.id}`, account)
}

const search = (query, size) => {
  const params = { q: query, size }
  return Vue.prototype.$http.get('accounts', { params })
}

const fetchAll = (pagination) => {
  const params = pagination
  return Vue.prototype.$http.get('accounts', { params })
}

const fetchOne = (accountId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}`)
}

const fetchApiKey = (accountId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/api-key`)
}

const fetchRates = (accountId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/rates`)
}

const fetchRate = (accountId, rateId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/rates/${rateId}`)
}

const removeRate = (accountId, rate) => {
  return Vue.prototype.$http.delete(`accounts/${accountId}/rates/${rate.id}`)
}

const createRate = (accountId, rate) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/rates`, rate)
}

const updateRate = (accountId, rate) => {
  return Vue.prototype.$http.put(`accounts/${accountId}/rates/${rate.id}`, rate)
}

const fetchStores = (accountId, pagination) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/stores`, { params: pagination })
}

const fetchStore = (accountId, storeId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/stores/${storeId}`)
}

const createStore = (accountId, store) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/stores`, store)
}

const updateStore = (accountId, store) => {
  return Vue.prototype.$http.put(`accounts/${accountId}/stores/${store.id}`, store)
}

const setMainStore = (accountId, storeId) => {
  return Vue.prototype.$http.patch(`accounts/${accountId}/stores/${storeId}/main`)
}

const fetchTemplates = (accountId, pagination) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/templates`, { params: pagination })
}

const fetchTemplate = (accountId, templateId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/templates/${templateId}`)
}

const createTemplate = (accountId, template) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/templates`, template)
}

const updateTemplate = (accountId, template) => {
  return Vue.prototype.$http.put(`accounts/${accountId}/templates/${template.id}`, template)
}

const fetchShops = (accountId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/shops`)
}

const fetchShop = (accountId, shopId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/shops/${shopId}`)
}

const updateShop = (accountId, shopId, name, sender) => {
  return Vue.prototype.$http.put(`accounts/${accountId}/shops/${shopId}?name=${name}&sender=${sender}`)
}

const activate = (accountId) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/activate`)
}

const deactivate = (accountId) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/deactivate`)
}

const fetchTokens = (accountId, pagination) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/tokens`, { params: pagination })
}

const fetchToken = (accountId, tokenId) => {
  return Vue.prototype.$http.get(`accounts/${accountId}/tokens/${tokenId}`)
}

const createToken = (accountId, token) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/tokens`, token)
}

const updateToken = (accountId, token) => {
  return Vue.prototype.$http.put(`accounts/${accountId}/tokens/${token.id}`, token)
}

const createUser = (accountId, email) => {
  return Vue.prototype.$http.post(`accounts/${accountId}/users?email=${email}`)
}

export default {
  create,
  update,
  search,
  fetchAll,
  fetchOne,
  fetchApiKey,
  fetchRates,
  fetchRate,
  createRate,
  updateRate,
  removeRate,
  fetchStores,
  fetchStore,
  createStore,
  updateStore,
  setMainStore,
  fetchTemplates,
  fetchTemplate,
  createTemplate,
  updateTemplate,
  fetchShops,
  fetchShop,
  updateShop,
  activate,
  deactivate,
  fetchTokens,
  fetchToken,
  createToken,
  updateToken,
  createUser,
}
