import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_DATA: (state, payload) => {
      Vue.set(state, `${payload.key}@data`, payload.body)
    },
    SET_PAGE: (state, payload) => {
      Vue.set(state, `${payload.key}@page`, payload.body)
    },
  },
  getters: {
    DATA: state => payload => {
      const key = `${payload}@data`
      if (typeof state[key] === 'undefined') {
        Vue.set(state, key, { data: [], items: 0 })
      }
      return state[key]
    },
    PAGE: state => payload => {
      const key = `${payload}@page`
      if (typeof state[key] === 'undefined') {
        Vue.set(state, key, { page: 1, size: 30 })
      }
      return state[key]
    },
  },
}
