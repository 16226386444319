import Vue from 'vue'
import Vuex from 'vuex'

import session from './session'
import settings from './settings'
import shipments from './shipments'
import platforms from './platforms'
import operation from './operation'
import results from './results'
import metrics from './metrics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    settings,
    shipments,
    platforms,
    operation,
    results,
    metrics,
  },
  state: {},
  mutations: {},
  actions: {},
})
