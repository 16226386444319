import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import { guard } from '@/auth/guard'
import acl from '@/auth/acl'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'metrics',
      component: MainLayout,
      children: [
        {
          path: '/metrics',
          component: () => import('./views/metrics'),
          meta: { authorize: acl.metrics.general },
        },
        {
          path: '/metrics/account',
          component: () => import('./views/metrics/account'),
          meta: { authorize: acl.metrics.account },
        },
        {
          path: '/shipments/create',
          component: () => import('./views/shipments/crud/create'),
          meta: { authorize: acl.shipments.create },
        },
        {
          path: '/shipments/success',
          component: () => import('./views/shipments/success'),
          meta: { authorize: acl.shipments.create },
        },
        {
          path: '/shipments/exported',
          component: () => import('./views/shipments/exported'),
          meta: { authorize: acl.shipments.export },
        },
        {
          path: '/shipments',
          component: () => import('./views/shipments'),
          meta: { authorize: acl.shipments.search },
        },
        {
          path: '/shipments/:shipment/detail',
          component: () => import('./views/shipments/detail'),
          meta: { authorize: acl.shipments.detail },
        },
        {
          path: '/shipments/:shipment/update',
          component: () => import('./views/shipments/crud/update'),
          meta: { authorize: acl.shipments.update },
        },
        {
          path: '/shipments/:shipment/promote',
          component: () => import('./views/shipments/crud/promote'),
          meta: { authorize: acl.shipments.promote },
        },
        {
          path: '/shipments/:shipment/items',
          component: () => import('./views/shipments/items'),
          meta: { authorize: acl.shipments.detail },
        },
        {
          path: '/couriers',
          component: () => import('./views/couriers/search'),
          meta: { authorize: acl.couriers.search },
        },
        {
          path: '/couriers/create',
          component: () => import('./views/couriers/update'),
          meta: { authorize: acl.couriers.create },
        },
        {
          path: '/couriers/:courier/detail',
          component: () => import('./views/couriers/detail'),
          meta: { authorize: acl.couriers.search },
        },
        {
          path: '/couriers/:courier/update',
          component: () => import('./views/couriers/update'),
          meta: { authorize: acl.couriers.update },
        },
        {
          path: '/accounts',
          component: () => import('./views/accounts/search'),
          meta: { authorize: acl.accounts.search },
        },
        {
          path: '/accounts/create',
          component: () => import('./views/accounts/update'),
          meta: { authorize: acl.accounts.create },
        },
        {
          path: '/accounts/:account/update',
          component: () => import('./views/accounts/update'),
          meta: { authorize: acl.accounts.update },
        },
        {
          path: '/accounts/:account/rates',
          component: () => import('./views/rates/search'),
          meta: { authorize: acl.rates.search },
        },
        {
          path: '/accounts/:account/rates/create',
          component: () => import('./views/rates/update'),
          meta: { authorize: acl.rates.create },
        },
        {
          path: '/accounts/:account/rates/:rate/update',
          component: () => import('./views/rates/update'),
          meta: { authorize: acl.rates.update },
        },
        {
          path: '/accounts/:account/shops',
          component: () => import('./views/shops'),
          meta: { authorize: acl.shops.admin },
        },
        {
          path: '/accounts/:account/stores',
          component: () => import('./views/stores/search'),
          meta: { authorize: acl.stores.search },
        },
        {
          path: '/accounts/:account/stores/create',
          component: () => import('./views/stores/update'),
          meta: { authorize: acl.stores.create },
        },
        {
          path: '/accounts/:account/stores/:store/update',
          component: () => import('./views/stores/update'),
          meta: { authorize: acl.stores.update },
        },
        {
          path: '/accounts/:account/templates',
          component: () => import('./views/templates/search'),
          meta: { authorize: acl.templates.search },
        },
        {
          path: '/accounts/:account/templates/create',
          component: () => import('./views/templates/update'),
          meta: { authorize: acl.templates.create },
        },
        {
          path: '/accounts/:account/templates/:template/update',
          component: () => import('./views/templates/update'),
          meta: { authorize: acl.templates.update },
        },
        {
          path: '/accounts/:account/tokens',
          component: () => import('./views/tokens/search'),
          meta: { authorize: acl.tokens.search },
        },
        {
          path: '/accounts/:account/tokens/create',
          component: () => import('./views/tokens/update'),
          meta: { authorize: acl.tokens.create },
        },
        {
          path: '/accounts/:account/tokens/:token/update',
          component: () => import('./views/tokens/update'),
          meta: { authorize: acl.tokens.update },
        },
        {
          path: '/accounts/:account/troubleshoot',
          component: () => import('./views/troubleshoot'),
          meta: { authorize: acl.accounts.update },
        },
        {
          path: '/tokens',
          component: () => import('./views/tokens/search'),
          meta: { authorize: acl.tokens.search },
        },
        {
          path: '/tokens/create',
          component: () => import('./views/tokens/update'),
          meta: { authorize: acl.tokens.create },
        },
        {
          path: '/tokens/:token/update',
          component: () => import('./views/tokens/update'),
          meta: { authorize: acl.tokens.update },
        },
        {
          path: '/shops',
          component: () => import('./views/shops'),
          meta: { authorize: acl.shops.search },
        },
        {
          path: '/shops/available',
          component: () => import('./views/shops/available'),
          meta: { authorize: acl.shops.create },
        },
        {
          path: '/shops/connect',
          component: () => import('./views/shops/connect'),
          meta: { authorize: acl.shops.create },
        },
        {
          path: '/shops/:shop/update',
          component: () => import('./views/shops/update'),
          meta: { authorize: acl.shops.update },
        },
        {
          path: '/shops/:shop/configure',
          component: () => import('./views/shops/configure'),
          meta: { authorize: acl.shops.create },
        },
        {
          path: '/shops/callback',
          component: () => import('./views/shops/callback'),
          meta: { authorize: acl.shops.create },
        },
        {
          path: '/payments',
          component: () => import('./views/payments/search'),
          meta: { authorize: acl.payments.search },
        },
        {
          path: '/zones',
          component: () => import('./views/operation/zones'),
          meta: { authorize: acl.zones.search },
        },
        {
          path: '/crossdock',
          component: () => import('./views/operation/crossdock'),
          meta: { authorize: acl.crossdock.search },
        },
        {
          path: '/tasks',
          component: () => import('./views/operation/tasks'),
          meta: { authorize: acl.tasks.search },
        },
        {
          path: '/simulator',
          component: () => import('./views/simulator'),
          meta: { authorize: acl.simulator.search },
        },
        {
          path: '/troubleshoot',
          component: () => import('./views/troubleshoot'),
          meta: { authorize: acl.shops.create },
        },
        {
          path: '/settings',
          component: () => import('./views/settings'),
          meta: { authorize: acl.settings.detail },
        },
        {
          path: '/exports',
          component: () => import('./views/exports'),
          meta: { authorize: acl.exports.create },
        },
        {
          path: '/users',
          component: () => import('./views/users/search'),
          meta: { authorize: acl.users.search },
        },
        {
          path: '/users/create',
          component: () => import('./views/users/update'),
          meta: { authorize: acl.users.create },
        },
        {
          path: '/users/:user/update',
          component: () => import('./views/users/update'),
          meta: { authorize: acl.users.update },
        },
      ],
    },
    // Errors
    {
      path: '/error',
      component: AuthLayout,
      children: [
        {
          path: '/error/403',
          component: () => import('./views/errors/403'),
          meta: {
            title: 'Not Authorized',
            meta: { authorize: false },
          },
        },
        {
          path: '/error/404',
          component: () => import('./views/errors/403'),
          meta: {
            title: 'Not Found',
            meta: { authorize: false },
          },
        },
        {
          path: '/error/500',
          component: () => import('./views/errors/403'),
          meta: {
            title: 'Server Error',
            meta: { authorize: false },
          },
        },
      ],
    },
    // Redirect to 404
    {
      path: '*', redirect: 'error/404', hidden: true,
    },
  ],
})

router.beforeEach(guard)

export default router
