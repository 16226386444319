import Vue from 'vue'

const search = (query) => {
  const params = { q: query }
  return Vue.prototype.$http.get('payments', { params })
}

const fetchAll = (pagination) => {
  const params = pagination
  return Vue.prototype.$http.get('payments', { params })
}

export default {
  search,
  fetchAll,
}
