<template>
  <a-affix v-if="account">
    <div class="bg-danger text-white p-3">
      <a-avatar
        class="bg-danger text-white mr-2"
        size="large"
        icon="exclamation-circle" />
      <span>{{ $t('msg.mock.description', {name: account.contact.name}) }}</span>
      <div class="float-right">
        <a-button
          class="text-white border-white"
          size="large"
          @click="finish"
          ghost>
          {{ $t('finish') }}
        </a-button>
      </div>
    </div>
  </a-affix>
</template>
<script>
export default {
  name: 'AccountMock',
  computed: {
    account() {
      return this.$store.getters['session/ACCOUNT']
    },
  },
  methods: {
    finish() {
      this.$store.commit('session/SET_ACCOUNT', undefined)
    },
  },
}
</script>
