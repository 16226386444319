import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles
import 'leaflet/dist/leaflet.css' // maps

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import BootstrapVue from 'bootstrap-vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Meta from 'vue-meta'

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import './antd'
import './registerServiceWorker'
import './filters'

// AUTH0 / Import the Auth0 configuration
import { domain, clientId, audience } from '../auth_config.json'

// AUTH0 / Import the plugin here
import { Auth0Plugin } from './auth'

// AUTH0 / Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})

// HTTP Client
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})
Vue.use(VueAxios, http)

Vue.use(BootstrapVue)

Vue.use(Meta)

Vue.use(NProgress, {
  http: false,
  router: false,
})

Vue.use(VuePageTitle, {
  prefix: 'Jipink Desk | ',
  router,
})

Vue.config.productionTip = false

const nprogress = new NProgress({
  parent: 'body',
  showSpinner: false,
})

Vue.mixin({
  computed: {
    account: function () {
      const param = this.$route.params.account
      if (param) {
        return param
      }
      const account = store.getters['session/ACCOUNT']
      if (account) {
        return account.id
      }
      return store.getters['session/USER'].account
    },
  },
})

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
  created: () => {
    http.interceptors.request.use(async config => {
      const token = await Vue.prototype.$auth.getTokenSilently()
      config.headers.Authorization = `Bearer ${token}`
      config.headers['Accept-Language'] = i18n.locale
      nprogress.start()
      return config
    }, error => {
      return Promise.reject(error)
    })
    http.interceptors.response.use(response => {
      nprogress.done()
      store.commit('session/SET_ONLINE')
      return response
    }, error => {
      nprogress.done()
      if (error.response && error.response.status === 403) {
        router.push('/error/403')
      } if (error.response) {
        const message = error.response.data.message || i18n.tc('msg.error.internal')
        Vue.prototype.$message.error(message)
        return Promise.reject(error)
      } else {
        store.commit('session/SET_OFFLINE')
      }
    })
  },
}).$mount('#app')
